.header {
  position: fixed;

  width: 100%;
  z-index: 10; /* Ensure header is above other content */
}
.container {
  display: none;
}
@media (max-width: 889px) {
  .header{
    padding:20px 24px;
    background-color: #fff;

  } 
}
.active {
  color: #0BA97A; /* Green for the active section */
}

@media (min-width: 890px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;

  }
  .scrolled{
    display: flex;
    justify-content: center;
     padding: 0;
  }
  .active {
    color: #0BA97A; /* Green for the active section */
  }
  .navContainer {
    display: flex;
    border-radius: 8px;
    background: var(--White, #fff);
    box-shadow: 0px 4px 20px 0px rgba(28, 28, 28, 0.08);
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding: 20px 72px;
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .navContainerlow {
    display: flex;
    border-radius: 0;
    background: var(--White, #fff);
    box-shadow: 0px 4px 20px 0px rgba(28, 28, 28, 0.08);
    align-items: center;
    justify-content: space-between;
    display: flex;
   width: 100%;;
    flex: 1;  
  padding: 20px 96px;
    margin: 0;
  }

  .navContainer h1 {
    font-size: 2rem;
    cursor: pointer;
    font-family: "Onest", sans-serif;
  }

  .nav {
    display: flex;
    gap: 2.5rem;
  }

  .nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav ul li {
    display: inline;
    font-family: "Onest", sans-serif;
  }

  .activeClass {
    font-weight: 400;
  }
}

