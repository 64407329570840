* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}
a{
  text-decoration: none;
  color: black

}
body {
  margin: 0;
  font-family: "Onest" sans-serif;
}

section {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-width: 1440px;
  margin: auto;
}

.section-1 {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 100px 24px 0 24px;
  overflow: hidden;
}

.section-1 h2{
  margin-top: 4px;
}
.section-1 p{
  margin-top: 20px;
}
.section-1 a{
  margin-top: 20px;
}
.text-14-green {
  color: var(--Accent-color, #0ba97a);
  text-align: center;
  font-feature-settings: "cv05" on;
  font-family: Onest;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
}
.text-32 {
  color: var(--Black, #090e0d);
  text-align: center;
  font-family: Onest;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
}
.text-14-grey {
  color: var(--Grey-800, #545454);
  text-align: center;
  text-align: center;
  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}

.text-20 {
  color: var(--Black, #090e0d);
  text-align: center;
  font-family: Onest;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
}
.action-btn {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Accent-color, #0ba97a);
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: "cv05" on;
  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.circle-container {
  width: 480px;
  height: 480px;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
}

.first-circle {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.second-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 85%;
  border-radius: 100%;
  border: 1px solid #e3e3e3;
}
.third-circle {
  width: 80%;
  height: 80%;
  border-radius: 100%;
  border: 1px solid #e3e3e3;
}
.phone {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 480px;
}

.phone img {
  position: absolute;
}
.responsive-image {
  content: url("/src/assests/mockup.png");
}

.phone1 img {
  position: absolute;
}
.phone1{
  display: none;
}
.content {
  width: 199.634px;
  height: 432.985px;
  border-radius: 35.396px;
  background: #000000;
}
.island {
  position: absolute;
  width: 63px;
  height: 18.265px;
  flex-shrink: 0;
  top: 31px;
  border-radius: 26.471px;
  background: var(--Black, #090e0d);
  z-index: 3;
}

.tag-container {
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  background: rgba(246, 246, 246, 0.84);
  visibility: 0.6;
}
.tag-text {
  color: var(--Black, #090e0d);
  font-feature-settings: "cv05" on;
  font-family: Onest;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.position-1 {
  position: absolute;
  top: 90px;
  left: 340px;
  z-index: 1;
}
.position-2 {
  position: absolute;
  top: 210px;
  left: 340px;
  z-index: 1;
}
.position-3 {
  position: absolute;
  bottom: 90px;
  left: 340px;
  z-index: 1;
}
.position-4 {
  position: absolute;
  top: 120px;
  right: 340px;
  z-index: 1;
}
.position-5 {
  position: absolute;
  top: 240px;
  right: 340px;
  z-index: 1;
}
.position-6 {
  position: absolute;
  bottom: 70px;
  right: 340px;
  z-index: 1;
}
.solution-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.solution-container-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 24px;
}

.join-container {
  display: flex;
  flex-direction: column;
  padding: 60px 24px;
}
.secondary-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 24px 0 24px;
}
.solution-container .text-14-grey {
  margin-top: 20px;
}
.solution-container h3 {
  margin-top: 4px;
}
.text-24 {
  color: var(--Black, #090e0d);
  text-align: center;
  font-family: Onest;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
}
.text-16 {
  color: var(--Black, #090e0d);
  font-feature-settings: "cv05" on;
  font-family: Onest;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.icon-container p {
  color: var(--Grey-400, #c3c3c3);
  text-align: center;
  font-feature-settings: "cv05" on;
  font-family: Onest;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}
.img-box {
  width: 100%;
  justify-content: center;
  align-items: center; 
  height: 252px;
}

.image-1 {
  width: 100%; 
  height: 100%; 
  object-fit: contain; 
}

.email-form {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  margin-top: 28px;
}
.email-form-c{

  width: 100%;
  margin-top: 0;}

.email-input {
  padding: 12px 16px;
  font-size: 16px;
  flex: 1;
  border-radius: 8px;
  border: 1px solid var(--Grey-300, #e3e3e3);
  background: var(--White, #fff);
  transition: border-color 0.3s;
  outline: none;
}
.email-input::placeholder{
  color: #C3C3C3;
}

.footer-container {
  display: flex;
  background-color: #000000;
  justify-content: center;
  align-items: center;
}
.footer-content {
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 24px 24px 24px;
  background-color: #000000;
  flex-direction: column;
  gap: 24px;
}
.email-input:focus {
  border: 1px solid black;
}

.email-input.invalid {
  border: 1px solid #cc0000;
}

.error-text {
  display: flex;
  color: #cc0000;
  font-size: 14px;
  font-family: Onest;
  margin-top: 4px;
  text-align: start;
  width: 100%;
}
.success-text {
  display: flex;
  color: #0ba97a;
  text-align: start;
  width: 100%;
  font-size: 14px;
  font-family: Onest;
  margin-top: 4px;
}
.insta-container {
  display: flex;
  color: var(--White, #fff);
  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.insta-container a {
  display: flex;
  color: var(--White, #fff);
  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  text-decoration: none;
}
.text-12-grey {
  color: var(--Grey-400, #c3c3c3);
  text-align: center;
  font-feature-settings: "cv05" on;
  font-family: Onest;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}

.image-set-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 32px;
  width: 100%;
}
.image-set {
  display: flex;
  align-items: center;
  justify-content: center;
}
.costumer-block {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.circle-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid white;
  object-fit: cover;
  margin-right: -15px;
}
.footer-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-top: 16px;
}
.col {
  display: flex;
  flex-direction: column;
}
.solution-container .col {
  order: 1;
}
.solution-container .img-box {
  order: 2;
}

@media (min-width: 890px) {
  .phone {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    left: 240px;
    transform: translate(-50%, -50%);
    width: 742px;
    height: 742px;
  }
  .island {
    top: 125px;
    width: 75.895px;
    height: 22.003px;
    flex-shrink: 0;
  }
  .responsive-image {
    content: url("/src/assests/largeScreenImage.png");
  }
  .tag-container {
    max-height: 35px;
    position: absolute;
  }

  .phone1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 72%;
    transform: translate(-50%, -50%);
    width: 742px;
    height: 742px;
  }

.phone1 img {
  position: absolute;
}
  .content {
    width: 237.89px;
    height: 515.96px;
    border-radius: 35.396px;
    background: #000000;
  }
  .position-1 {
    position: absolute;
    top: 620px;
    left: 0;
    max-width: 161px;
  }
  .position-2 {
    max-width: 110px;
  position: absolute;
  top: 300px;
  left: auto;
  right: -80px;

  }
  .position-3 {
    max-width: 159px;
    top: 500px;
    right: -100px;
    left: auto;
  }
  .position-4 {
    top: 200px;
    left: -60px;
    max-width: 138px;
  }
  .position-5 {
    max-width: 97px;
    top: 160px;
    right: -30px;
  }
  .position-6 {
    top: 390px;
    left: -100px;
    max-width: 138px;
  }
  .tag-text {
    font-size: 14px;
  }
  .tag-container svg {
    width: 18px;
    height: 18px;
  }
  .tag-container {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: rgba(246, 246, 246, 0.84);
    visibility: 0.6;
  }
}
@media (min-width: 420px) {
  .solution-container {
    gap: 24px;
  }
}
@media (min-width: 890px) {
  .solution-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 66px;
  }
  .text-32 {
    font-size: 40px;
  }
  .solution-container-text {
    padding: 60px;
    max-width: 820px;
  }
  .solution-container-text .text-14-grey {
    margin-top: 28px;
  }
  .solution-container-text h3 {
    margin-top: 4px;
  }

  .solution-container.order-1 .col {
    order: 1;
  }
  .solution-container.order-1 .img-box {
    order: 2;
  }

  .solution-container.order-2 .col {
    order: 2;
  }
  .solution-container.order-2 .img-box {
    order: 1;
  }
  .action-btn {
    font-size: 16px;
    align-self: center;
  }
  .footer-content {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding: 72px 120px 32px 120px;
    background-color: #000000;
  }
  .join-container {
    padding: 60px 120px;
  }
  .secondary-section {
    padding: 60px 120px 0 120px;
  }
  .footer-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0;
  }
  .image-set-container {
    justify-content: center;
  }
  .image-set-container img {
    height: 44px;
    width: 44px;
  }
  .insta-container {
    order: 2;
  }
  .insta-container {
    display: flex;
    color: var(--White, #fff);
    font-family: Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  .insta-container a {
    display: flex;
    color: var(--White, #fff);
    font-family: Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    text-decoration: none;
  }

  .text-14-green {
    font-size: 14px;
  }
  .solution-container-1 {
    padding: 0 120px;
  }
  .image-set-container {
    margin-top: 60px;
  }
  .text-14-grey {
    font-size: 16px;
  }
  .img-box {
    display: flex;
    width: 627px;
    height: 400px;
   height: 100%;
  }

  .image-1 {
    width: 100%; 
    height: 100%; 
  
  }
  .start {
    text-align: start;
  }

 
  .col {
    display: flex;
    width: 42%;
    justify-content: start;
    align-items: start;
  }
  .circle-container {
    width: 742px;
    height: 742px;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: visible;
    position: relative;
  }
  .section-1 {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    max-width: 800px;
    padding: 170px 24px 0 24px;
    overflow: hidden;
  }
   .email-form-c{
    max-width: 420px;
    width: 100%;
    margin-top: 0;
   }
  .email-form {
    max-width: 420px;
    width: 100%;
    margin-top: 0;
  }
  .text-24 {
    font-size: 32px;
  }
  .section-1 p{
    margin-top: 28px;
  }
  .section-1 a{
    margin-top: 28px;
  }
  
}

.circe-absolut{
  position: absolute;

}
.burger-nav{
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-height: 580px;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.burger-nav ul li{
  color: var(--Black, #090E0D);
text-align: center;
font-family: Onest;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 24px */
}
.drawer{
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}
.burger{
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}
.solution-container-text .text-14-grey {
  margin-top: 20px;
}
.solution-container-text h3 {
  margin-top: 4px;
}
@media (min-width: 890px) {
  .burger{
    display: none;
  }
  .drawer{display: none;}
}

.list:hover{
  color: var(--Accent-color, #0BA97A);

}
